import {defineStore} from "pinia";
import {AppState, Link, Notification, Roles} from "@/stores/types";
import api, {ErrorMode} from "@/api";
import axios, {AxiosResponse} from "axios";
import Swal from "sweetalert2";
import {DateTime} from "luxon";

// @ts-ignore
import * as Sentry from "@sentry/vue";
import layoutHelpers from "@/layout/helpers";

// TODO: should we split these out into auth/app/static/config stores?
export const useAppStore = defineStore("app", {
  state: (): AppState => ({
    // auth
    allRoles: [],
    user: null,
    showChristmasGift: false,

    // app
    isLC: false,
    isDarkMode: false,
    zone: "America/New_York", // server time zone
    timezone: localStorage.timezone ?? "system",
    notifications: [],
    loading: false,
    sideNavCollapsed: true,
    // types
    noteTypes: [],
    logReasonTypes: [],

    error: {
      title: null,
      message: null,
      isDismissible: false,
      links: [],
    },

    backendURL: import.meta.env.VITE_BACKEND_URL,
    version: import.meta.env.VITE_VERSION || "0.0.0",

    // config
    config: {
      itemsPhysical: {},
      itemsVirtual: {},
      vehicles: {},
      charges: [],
      goals: {},
      licenses: {},
    },
  }),

  actions: {
    fetchIndex() {
      return new Promise<void>((resolve, reject) => {
        // @ts-ignore
        api
          .get("/stats/self/", {errorMode: ErrorMode.NONE})
          .then((resp: AxiosResponse) => {
            resp.data.user.roles = new Roles(resp.data.user.roles);
            this.user = resp.data.user;
            this.allRoles = resp.data.allRoles;
            this.showChristmasGift = resp.data.showChristmasGift;

            Sentry.getCurrentScope().setUser({
              id: this.user?.steamId,
              username: this.user?.name,
            });

            return resolve();
          })
          .catch((err) => {
            return reject(err);
          });
      });
    },
    fetchNotifications() {
      return new Promise<void>((resolve, reject) => {
        // @ts-ignore
        api
          .get("/stats/notifications/", {errorMode: ErrorMode.NONE})
          .then((resp: AxiosResponse) => {
            this.notifications = resp.data.notifications;
            return resolve();
          })
          .catch((err) => {
            return reject(err);
          });
      });
    },
    fetchConfig() {
      return Promise.allSettled([
        axios.get(`/config/vehicles.json?v=${this.version}`).then((resp: AxiosResponse) => {
          this.config.vehicles = resp.data;
        }),
        axios.get(`/config/items_physical.json?v=${this.version}`).then((resp: AxiosResponse) => {
          this.config.itemsPhysical = resp.data;
        }),
        axios.get(`/config/items_virtual.json?v=${this.version}`).then((resp: AxiosResponse) => {
          this.config.itemsVirtual = resp.data;
        }),
        axios.get(`/config/charges.json?v=${this.version}`).then((resp: AxiosResponse) => {
          this.config.charges = resp.data;
        }),
        axios.get(`/config/goals.json?v=${this.version}`).then((resp: AxiosResponse) => {
          this.config.goals = resp.data;
        }),
        axios.get(`/config/player_licenses.json?v=${this.version}`).then((resp: AxiosResponse) => {
          this.config.licenses = resp.data;
        }),
      ]);
    },
    fetchNoteTypes() {
      if (this.noteTypes.length) return;

      api.get("/lc/notes/types/").then((resp) => {
        this.noteTypes = resp.data.types;
      });
    },
    async fetchLogReasonTypes() {
      if (this.logReasonTypes.length) return;

      await api.get("/lc/lclogs/types/").then((resp) => {
        this.logReasonTypes = resp.data.types;
      });
    },
    dismissNotification(notification?: Notification): Promise<void> {
      const url = notification
        ? `/stats/notifications/${notification.id}/`
        : "/stats/notifications/";
      return new Promise<void>((resolve, reject) => {
        api
          .delete(url)
          .then(() => {
            this.fetchNotifications().then(() => {
              resolve();
            });
          })
          .catch((err) => {
            reject(err);
          });
      });
    },

    // "mutations"
    async setLCMode(mode: boolean) {
      if (!this.user?.roles.LIFE_CONTROL) return;

      if (!this.user?.roles.LIFE_CONTROL_UNPROMPTED && mode) {
        const lastRef = JSON.parse(localStorage.getItem("lcEnteredRef") ?? "{}");
        if (
          !lastRef.timestamp ||
          DateTime.fromISO(lastRef.timestamp).diffNow().as("minutes") < -7.5
        ) {
          const result = await Swal.fire({
            title: "Not for personal use!",
            text: "LC3 is not for personal use. Access will be revoked if used outside of departmental duties.",
            icon: "warning",
            showCancelButton: true,
            confirmButtonText: "Enter LC",
            confirmButtonColor: "#0FBF7E", // TODO: this is bad, but i'm lazy and don't want to do scss rn
            reverseButtons: true,
            input: "text",
            inputLabel: "Reference (support ticket, or reason)",
            showLoaderOnConfirm: true,
            inputValidator: (value) => {
              if (value.length < 4) return "Invalid reference";
              return null;
            },
          });
          if (!result.isConfirmed) return;

          try {
            await api.post("/lc/entered/", {
              lcRef: result.value,
            });
          } catch (e) {
            console.error("Error entering LC:", e);
            return;
          }
          localStorage.setItem(
            "lcEnteredRef",
            JSON.stringify({
              lcRef: result.value,
              timestamp: DateTime.now().toJSON(),
            }),
          );
        } else {
          await api.post("/lc/entered/", {
            lcRef: `Reentry with previous ref: ${lastRef.lcRef}`,
          });
        }
      } else {
        localStorage.removeItem("lcEnteredRef");
      }
      // Fetch the log reason types after
      try {
        await this.fetchLogReasonTypes();
      } catch (e) {
        // Not preventing the user from entering LC if it fails
        console.error("Error fetching log reason types.", e);
      }

      this.isLC = mode;
    },
    async toggleLCMode() {
      await this.setLCMode(!this.isLC);
    },
    toggleDarkMode() {
      if (this.isDarkMode) {
        localStorage.isDarkMode = "false";
        this.isDarkMode = false;
      } else {
        localStorage.isDarkMode = "true";
        this.isDarkMode = true;
      }
    },
    setDarkMode(mode: boolean) {
      localStorage.isDarkMode = mode;
      this.isDarkMode = mode;
    },
    setTimeZone(zone: string | null) {
      zone = zone ?? "system";
      localStorage.timezone = zone;
      this.timezone = zone;
    },
    setError({
      title,
      message,
      isDismissible,
      links,
    }: {
      title: string;
      message?: string;
      isDismissible?: boolean;
      links?: Array<Link>;
    }) {
      this.error.title = title;
      this.error.message = message ?? null;
      this.error.isDismissible = isDismissible ?? false;
      this.error.links = links ?? [];
      this.loading = false;
    },
    clearError() {
      this.error.title = null;
      this.error.message = null;
      this.error.isDismissible = false;
      this.error.links = [];
    },
    setLoading(loading: boolean) {
      this.loading = loading;
    },
    withLoading(p: Promise<any>): Promise<any> {
      this.setLoading(true);
      return p.finally(() => this.setLoading(false));
    },
    closeSidenav() {
      this.sideNavCollapsed = true;
      layoutHelpers.setCollapsed(true, true);
    },
    toggleSideNav() {
      layoutHelpers.setCollapsed(!this.sideNavCollapsed);
      this.sideNavCollapsed = !this.sideNavCollapsed;
    },
  },
});
